import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  grid-column-gap: 1rem;
  width: 25%;
  height: 160px;
  background: rgba(32, 34, 39, 0.6);
  border-radius: 6px;
  padding: 1rem;

  @media screen and (max-width: 480px) {
    width: 100%;
  }

  @media (min-width: 768px) {
    width: 50%;
  }

  @media (min-width: 1200px) {
    width: 25%;
  }

  picture {
    position: relative;
    width: 96px;
    height: 128px;
    border-radius: 6px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
      content: "";
      width: 100%;
      height: 100%;
      background: linear-gradient(
        205deg,
        rgba(0, 0, 0, 0) 31.36%,
        #000000 123.07%
      );
      position: absolute;
      top: 0;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .info {
    flex: 1;
    text-decoration: none;

    & > span {
      font-weight: bold;
      font-size: 0.875rem;
      line-height: 145%;
      letter-spacing: -0.005em;
      color: ${({ theme }) => theme.colors.primary};
    }

    & > p {
      font-weight: 600;
      font-size: 1rem;
      line-height: 115%;
      letter-spacing: -0.005em;
      color: #ffffff;
      margin: 4px 0px 29px 0px;
    }

    a {
      text-decoration: none;
      transition: all 0.3s ease-in-out;

      &:hover {
        .info-progress {
          button {
            .icon {
              background: ${({ theme }) => theme.colors.graydark};

              svg {
                color: ${({ theme }) => theme.colors.primary};
              }
            }
          }
        }
      }

      .progress-bar {
        width: 100%;
        height: 5px;
        border-radius: 55px;
        background: #424449;
        margin-bottom: 1rem;
        overflow: hidden;

        & > div {
          background: #86888d;
          width: 60%;
          height: 100%;
          border-radius: 55px;
        }
      }

      .info-progress {
        display: flex;
        align-items: center;
        justify-content: space-between;

        button {
          display: flex;
          align-items: center;
          background: transparent;
          border: 0;
          padding: 0;

          .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20px;
            height: 20px;
            background: ${({ theme }) => theme.colors.title};
            border-radius: 50%;
            margin-right: 12px;
            transition: all 0.3s;

            svg {
              font-size: 9px;
              color: ${({ theme }) => theme.colors.primary};
              transition: all 0.3s ease-in-out;
            }
          }

          & > span {
            color: #ffffff;
            opacity: 0.8;
            transition: all 0.3s;
          }
        }

        strong {
          font-weight: 600;
          font-size: 0.875rem;
          line-height: 145%;
          letter-spacing: -0.005em;
          color: #86888d;
        }
      }
    }
  }
`;
