import httpAxios from "./index";

class EpisodeClass {
  resource = "api/episodio";

  watch<T = any>(id: string) {
    return httpAxios.get<T>(`${this.resource}/${id}/watch`);
  }
}

const episodeHttp = new EpisodeClass();

export default episodeHttp;
