import { useNavigate } from "react-router-dom";

import { BsCheck2 } from "react-icons/bs";

import { Container } from "./styles";
import { EpisodeType } from "../../types/Episode";
import { secToTime } from "../../helper/functions";
import clsx from "clsx";

interface EpisodeCardProps extends EpisodeType {
  small?: boolean;
  index?: number;
  inactive?: boolean;
  progress?: number;
}

export const EpisodeCard = ({
  small = false,
  id,
  course_id,
  title,
  description,
  thumb,
  duration,
  index,
  inactive = false,
  progress = 0,
}: EpisodeCardProps) => {
  const navigate = useNavigate();
  const order = index ? index + 1 : 1;

  return (
    <Container key={id}>
      <button
        type="button"
        onClick={() => navigate(`/cursos/watch/${course_id}/${id}`)}
        className={small ? "card small" : "card"}
      >
        <picture className={small ? "small" : ""}>
          <img
            src={`${process.env.REACT_APP_URL_SPACE}/${thumb}`}
            alt={title}
            className={clsx({ inactive: inactive })}
            loading="lazy"
          />
          <div className="progress">
            <div className="bar" style={{ width: `${progress}%` }}></div>
          </div>
          <span>{secToTime(duration, 4)}</span>
        </picture>

        <div className="info">
          <div className="tags">
            <span>{`Episódio ${order}`}</span>
            {progress >= 100 && (
              <div className="flag">
                Concluído <BsCheck2 />
              </div>
            )}
          </div>

          <h6>{title}</h6>
          {!small && <p>{description}</p>}
        </div>
      </button>
    </Container>
  );
};
