//import { Apps } from '@mui/icons-material';
import { Avatar, Badge, Box, IconButton } from "@mui/material";
import FlexBox from "components/Mui/FlexBox";
import { H6, Tiny } from "components/Mui/Typography";
import { FC, Fragment, useRef, useState } from "react";
import PopoverLayout from "./PopoverLayout";
import { BsGrid } from "react-icons/bs";

// dummy  data
const services = [
  {
    id: "1",
    title: "Comunidade",
    body: "Salas de estudo, fóruns e repositório de materiais",
    image: "/static/logo/comunidade.png",
    url: "https://comunidade.ttribo.com",
  },
  {
    id: "2",
    title: "Cursos",
    body: "Cursos sobre estudo, mente e corpo",
    image: "/static/logo/aprendizagem.png",
    url: "https://app.ttribo.com",
  },
  {
    id: "3",
    title: "Meu perfil",
    body: "Informações da conta",
    image: "/static/logo/profile.png",
    url: "https://account.ttribo.com",
  },
];

const AppPopover: FC = () => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  return (
    <Fragment>
      <IconButton ref={anchorRef} onClick={() => setOpen(true)}>
        <Badge color="error" badgeContent={0}>
          <BsGrid color={"white"} />
        </Badge>
      </IconButton>

      <PopoverLayout
        hiddenViewButton
        popoverOpen={open}
        anchorRef={anchorRef}
        title="TTribo Apps"
        popoverClose={() => setOpen(false)}
      >
        {services.map((service) => (
          <ListItem service={service} key={service.id} />
        ))}
      </PopoverLayout>
    </Fragment>
  );
};

// -----------------------------------------------------------------

type ListItemProps = {
  service: {
    image: string;
    title: string;
    body: string;
    url: string;
  };
};
function ListItem({ service }: ListItemProps) {
  return (
    <a href={service.url} style={{ textDecoration: "none" }}>
      <FlexBox
        p={2}
        alignItems="center"
        sx={{
          cursor: "pointer",
          "&:hover": {
            color: "#000",
            backgroundColor: "#fff",
          },
        }}
      >
        <Avatar src={service.image} sx={{ width: 35, height: 35 }} />

        <Box ml={2}>
          <H6>{service.title}</H6>
          <Tiny display="block" fontWeight={500}>
            {service.body}
          </Tiny>
        </Box>
      </FlexBox>
    </a>
  );
}

export default AppPopover;
