import { useNavigate } from "react-router-dom";
import { Container } from "./styles";
import { EpisodeType } from "../../types/Episode";
import clsx from "clsx";

export const EpisodeCardPlayer2 = ({
  id,
  course_id,
  title,
  thumb,
  description,
  progress,
  inactive = true,
}: EpisodeType & { progress: number; inactive: boolean }) => {
  const navigate = useNavigate();

  return (
    <Container key={id}>
      <button
        type="button"
        onClick={() => navigate(`/cursos/watch/${course_id}/${id}`)}
        className="card"
      >
        <picture>
          <img
            src={`${process.env.REACT_APP_URL_SPACE}/${thumb}`}
            alt={title}
            loading="lazy"
            className={clsx({ inactive: inactive })}
          />
          <div className="progress">
            <div className="bar" style={{ width: `${progress}%` }}></div>
          </div>
        </picture>

        <div className="info">
          <h4>{title}</h4>
          <p>{description}</p>
        </div>
      </button>
    </Container>
  );
};
