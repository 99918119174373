import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { IoIosArrowBack, IoMdLock } from "react-icons/io";

import { Container } from "./styles";
import { CoursesType } from "../../types/Courses";
import courseHttp from "../../repositores/course";
import { EpisodeType } from "../../types/Episode";

import { CarrouselEpisodes } from "../../components/CarrouselEpisodes";
import episodeHttp from "../../repositores/episode";
import { VimeoPlayer } from "../../components/Players/VimeoPlayer";

export const CourseWatch = (): JSX.Element => {
  const navigate = useNavigate();
  const { courseId, episodioId } = useParams<{
    courseId: string;
    episodioId: string;
  }>();

  const [course, setCourse] = useState<CoursesType | null>(null);
  const [episodio, setEpisodio] = useState<EpisodeType | null>(null);
  const [loading, setLoading] = useState(false);
  const [progressEpisodios, setProgressEpisodios] = useState<
    {
      episode_id: string;
      percent: number;
    }[]
  >([]);

  useEffect(() => {
    document.title = `${course?.title} | TTribo.com`;
  }, [course]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    async function getCourse() {
      if (courseId) {
        try {
          const [course, progress] = await Promise.all([
            courseHttp.get(courseId),
            courseHttp.episodiosProgress(courseId),
          ]);
          setCourse(course.data.data);
          setProgressEpisodios(progress.data);
        } catch (e) {
          console.log(e);
        }
      }
    }
    getCourse();
  }, [courseId]);

  useEffect(() => {
    async function getEpisode() {
      setLoading(true);
      if (episodioId) {
        try {
          const response = await episodeHttp.watch(episodioId);
          if (response.status === 200) {
            setEpisodio(response.data.data);
          } else {
            setEpisodio(null);
          }
        } catch (e) {
          console.log(e);
          setEpisodio(null);
        } finally {
          setLoading(false);
        }
      }
    }
    getEpisode();
  }, [episodioId]);

  return (
    <Container>
      <section className="watch">
        <div className="container">
          <div className="title">
            <button
              type="button"
              onClick={() => navigate(`/cursos/details/${course?.id}`)}
            >
              <IoIosArrowBack />
              <h2>{episodio?.title}</h2>
            </button>
          </div>

          <div className="player">
            {!loading ? (
              <div className="video">
                {episodio ? (
                  <div>
                    {episodio?.video_id && course?.id && (
                      <VimeoPlayer
                        videoId={episodio.video_id}
                        courseId={course.id}
                        episodeId={episodio.id}
                      />
                      // <YoutubePlayer
                      //   videoId={episodio.video_id}
                      //   courseId={course.id}
                      //   episodeId={episodio.id}
                      // />
                    )}
                  </div>
                ) : (
                  <section className={"payment"}>
                    <h2>Acesso restrito.</h2>
                    <h2>Venha fazer parte do TTribo.com!</h2>
                    <h3>
                      <IoMdLock />
                      Área reservada para assinantes.
                    </h3>
                    <div>
                      <div className="buttons">
                        <a
                          href={course?.landing_page}
                          className="btn-white"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Saiba mais!
                        </a>
                      </div>
                    </div>
                  </section>
                )}
              </div>
            ) : (
              <div>Carregando...</div>
            )}
          </div>

          <div className="class-list">
            <div className="wrapper-class">
              <div className="top">
                <h4>Todos episódios</h4>
              </div>
              {course?.episodes && (
                <CarrouselEpisodes
                  course={course}
                  episodesProgress={progressEpisodios}
                />
              )}
            </div>
          </div>

          <div className="resume">
            <div className="class-description">
              <h3>Resumo curso</h3>
              <p>{course?.description}</p>
            </div>
          </div>
          {course?.partner && (
            <div className="center">
              <div className="center-image-name">
                <div className="foto">
                  <img
                    src={`${process.env.REACT_APP_URL_SPACE}/${course?.partner?.avatar}`}
                    alt={course?.partner?.name}
                    loading="lazy"
                  />
                </div>
                <div className="info">
                  <strong>{course?.partner?.name}</strong>
                  <p>{course?.partner?.caption}</p>
                </div>
              </div>
              <div className="center-description">
                <strong>Sobre</strong>
                <br />
                {course?.partner?.description}
              </div>
            </div>
          )}
        </div>
      </section>
    </Container>
  );
};
