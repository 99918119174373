import httpAxios from "./index";

class PartnersClass {
  resource = "api/parceiros";

  list<T = any>() {
    return httpAxios.get<T>(this.resource);
  }

  get<T = any>(id: string) {
    return httpAxios.get<T>(`${this.resource}/${id}`);
  }
}

const partnersHttp = new PartnersClass();

export default partnersHttp;
