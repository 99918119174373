import { ReactNode } from "react";
import { ThemeProvider } from "styled-components";

import theme from "../themes";

import { AuthProvider } from "./SanctumTokenContext";

type AppProviderProps = {
  children: ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => (
  <ThemeProvider theme={theme}>
    <AuthProvider>{children}</AuthProvider>
  </ThemeProvider>
);
