import { Routes as Switch, Route } from "react-router-dom";

import { AuthLayout } from "Layout";

import { Dashboard } from "pages/Dashboard";

import { Courses } from "pages/Courses";
import { CourseDetails } from "pages/CourseDetails";
import { CourseWatch } from "pages/CourseWatch";
import { Partners } from "pages/Partners";
import { PartnerDetails } from "pages/PartnerDetails";
import AuthGuard from "../components/guards/AuthGuards";
import { CoursesTtriboMais } from "../pages/CoursesTtriboMais";

export const Routes = (): JSX.Element => {
  return (
    <Switch>
      {/*<Route element={<GuestLayout />}>*/}
      {/*  <Route path="/signin" element={<SignIn />} />*/}
      {/*</Route>*/}

      <Route
        element={
          <AuthGuard>
            <AuthLayout />
          </AuthGuard>
        }
      >
        <Route path="/" element={<Dashboard />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/cursos" element={<Courses />} />
        <Route path="/cursos/details/:courseId" element={<CourseDetails />} />
        <Route
          path="/cursos/watch/:courseId/:episodioId"
          element={<CourseWatch />}
        />
        <Route path="/parceiros" element={<Partners />} />
        <Route
          path="/parceiros/details/:partnerId"
          element={<PartnerDetails />}
        />
        <Route path="/ttribo-mais" element={<CoursesTtriboMais />} />
      </Route>
    </Switch>
  );
};
