import * as React from "react";
import { VscSearch } from "react-icons/vsc";
import { Container } from "./styles";
import { FC, useEffect, useState } from "react";
import categoryHttp from "../../repositores/category";
import { CategoryType } from "../../types/Categories";

interface FilterProps {
  search: string;
  setSearch: (value: string) => void;
  category: string;
  setCategory: (value: string) => void;
}
export const Filters: FC<FilterProps> = ({
  search,
  category,
  setSearch,
  setCategory,
}) => {
  const [categories, setCategories] = useState<CategoryType[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getCategories() {
      setLoading(true);
      try {
        const { data } = await categoryHttp.list();
        setCategories(data.data);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    }
    getCategories();
  }, []);
  return (
    <Container>
      <div className="filters">
        <div className="filters-search">
          <form>
            <div className="control-input">
              <input
                type="search"
                value={search}
                name="search"
                placeholder="Buscar ..."
                onChange={(e) => setSearch(e.target.value)}
              />

              <VscSearch />
            </div>
          </form>
        </div>

        <div className="filters-categories">
          <select
            name="categories"
            value={category}
            disabled={loading}
            onChange={(e) => setCategory(e.target.value)}
          >
            <option>Selecione Cateogira</option>
            {categories.map((row) => (
              <option value={row.id} key={row.id}>
                {row.title}
              </option>
            ))}
          </select>
        </div>
      </div>
    </Container>
  );
};
