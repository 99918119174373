import styled from "styled-components";

export const Container = styled.div`
  .swiper {
    &:hover {
      .swiper-button-prev,
      .swiper-button-next {
        opacity: 1;
      }
    }
    &-button-disabled {
      visibility: hidden;
      opacity: 0;
      transition: 0.5s ease-in-out;
    }

    &-slide {
      .card {
        position: relative;
        max-width: 100%;
        display: block;
        overflow: hidden;
        text-decoration: none;
        @media (max-width: 991px) {
          width: 80%;
          left: 10%;
        }

        &:hover {
          picture {
            img {
              transform: scale(1.03);
            }
          }
        }

        picture {
          position: relative;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 1.25rem;
          overflow: hidden;

          &::before {
            content: "";
            width: 100%;
            height: 100%;
            background: linear-gradient(
              180deg,
              rgba(15, 17, 22, 0.1) 71.36%,
              #0f1116 111.67%
            );
            position: absolute;
            top: 0;
            left: 0;
            pointer-events: none;
            background-size: cover !important;
          }

          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: 10px;
            object-position: center;
            transition: transform 0.3s;
          }
        }

        .info {
          h4 {
            height: 40px;
            font-size: 1.3rem;
            line-height: 1.5rem;
            color: ${({ theme }) => theme.colors.white};
          }

          p {
            margin-top: 0.6rem;
            opacity: 0.8;
            font-size: 1rem;
            color: ${({ theme }) => theme.colors.white};
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
      }
    }

    &-button-next,
    &-button-prev {
      position: absolute;
      top: 0;
      width: 3.5rem;
      height: 100%;
      background: linear-gradient(90deg, #0b0b0e, transparent);
      z-index: 2;
      opacity: 0;
      transition: opacity 0.3s;

      &:after {
        font-size: 2rem;
        color: ${({ theme }) => theme.colors.white};
      }
    }

    &-button-prev {
      left: 0;
      background: linear-gradient(90deg, #000 2.46%, transparent 70%);
    }

    &-button-next {
      right: 0;
      background: linear-gradient(266.88deg, #000 2.46%, transparent 70%);
    }
  }
`;
