import styled from "styled-components";

export const Container = styled.div`
  .swiper {
    &-slide {
      .overlay {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background: linear-gradient(0deg, #0f1116 0, transparent 50%),
          linear-gradient(45.72deg, #0f1116 8.71%, rgba(15, 17, 22, 0) 72.99%);
        padding-top: 10rem;
        padding-left: 1.5625rem;
        padding-right: 1.5625rem;

        @media (min-width: 768px) {
          padding-top: 10rem;
        }

        .text {
          span {
            background-image: linear-gradient(
              267.26deg,
              ${({ theme }) => theme.colors.primary} -0.71%,
              ${({ theme }) => theme.colors.primarylight} 130.41%
            );
            color: transparent;
            -webkit-background-clip: text;
            background-clip: text;
            font-weight: 700;
            font-size: 1.1rem;

            @media (min-width: 768px) {
              font-size: 1.8rem;
            }
          }

          h3 {
            font-size: 1.3rem;
            margin-top: 1rem;
            line-height: 100%;
            max-width: 50rem;
            color: ${({ theme }) => theme.colors.white};

            @media (min-width: 992px) {
              font-size: 3.4rem;
              margin-top: 2.6rem;
            }
          }

          p {
            max-width: 40rem;
            font-size: 0.875rem;
            font-weight: 400;
            margin-top: 1rem;
            line-height: rem;
            color: ${({ theme }) => theme.colors.white};

            @media (min-width: 992px) {
              margin-top: 2.5rem;
            }
          }
        }

        .buttons {
          margin-top: 2rem;
          display: flex;
          gap: 10px;

          @media (min-width: 992px) {
            margin-top: 3.5rem;
            gap: 20px;
          }

          a {
            width: 100%;
            height: 3rem;
            display: flex;
            border-radius: 8px;
            align-items: center;
            justify-content: center;
            transition: all 0.3s;
            cursor: pointer;
            font-weight: 700;
            font-size: 1.2rem;
            text-align: center;
            text-decoration: none;

            @media (min-width: 992px) {
              width: 15rem;
              height: 3.6rem;
              font-size: 1.5rem;
            }

            &.btn-color {
              background-color: ${({ theme }) => theme.colors.graydark};
              color: ${({ theme }) => theme.colors.graydark};
            }
            }
          }
        }
      }
    }

    &-pagination {
      bottom: 5rem !important;

      @media (min-width: 992px) {
        bottom: 18rem !important;
      }

      &-bullet {
        height: 0.5rem;
        width: 6rem;
        opacity: 0.4;
        background-color: ${({ theme }) => theme.colors.white};
        margin: 0 3px;
        border-radius: 0;
        transition: opacity 0.3s;

        &.swiper-pagination-bullet-active {
          opacity: 1;
        }
      }
    }
  }
`;
