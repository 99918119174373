import styled from "styled-components";

export const Container = styled.section`
  .swiper {
  &-button-disabled {
      visibility: hidden;
      opacity: 0;
      transition: 0.5s ease-in-out;
    }
    &:hover {
      .swiper-button-prev,
      .swiper-button-next {
        opacity: 1;
      }
    }

    &-button-next,
    &-button-prev {
      position: absolute;
      top: 22px;
      width: 3.5rem;
      height: 100%;
      background: linear-gradient(90deg, #0b0b0e, transparent);
      z-index: 2;
      opacity: 0;
      transition: opacity 0.3s;

      &:after {
        font-size: 2rem;
        color: ${({ theme }) => theme.colors.white};
      }
    }

    &-button-prev {
      left: 0;
      background: linear-gradient(90deg, #000 2.46%, transparent 70%);
    }

    &-button-next {
      right: 0;
      background: linear-gradient(266.88deg, #000 2.46%, transparent 70%);
    }

    .padding-recently {
      padding: 7.8rem; 0; !important
    }
  }
`;
