import styled from "styled-components";

export const Container = styled.div`
  .partner-header {
    position: relative;
    width: 100%;
    height: 65vh;
    padding-top: 6rem;

    @media (min-width: 320px) {
      height: 510px;
    }
    @media (min-width: 480px) {
      height: 60vh;
    }
    @media (min-width: 600px) {
      height: 700px;
    }
    @media (min-width: 801px) {
      height: 80vh;
    }
    @media (min-width: 1025px) {
      height: 80vh;
    }
    @media (min-width: 1281px) {
      height: 880px;
    }
    @media (min-width: 1440px) {
      height: 930px;
    }

    @media (min-width: 1920px) {
      height: 1178px;
      padding-top: 10.5rem;
    }

    &--banner {
      width: 100%;
      height: 100%;

      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;

      &:before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        pointer-events: none;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.5) 0%,
          rgba(0, 0, 0, 0) 40.58%,
          #000000 107.57%
        );
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    .container {
      position: relative;
      z-index: 2;

      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      box-sizing: border-box;
      flex-direction: column;

      @media (min-width: 992px) {
        flex-direction: row;
      }
    }

    &--text {
      flex: 1;
      max-width: 632px;

      h1 {
        margin-top: 1rem;
        margin-bottom: 1rem;
        font-size: 2.5rem;
        line-height: 115%;
        font-weight: bold;
        color: ${({ theme }) => theme.colors.white};

        @media (min-width: 992px) {
          margin-top: 1.5rem;
          margin-bottom: 1rem;
          font-size: 3.5rem;
          line-height: 115%;
        }
      }

      p {
        max-width: 488px;
        margin-bottom: 3rem;
        line-height: 150%;
        color: ${({ theme }) => theme.colors.white};
      }

      > div {
        display: flex;
        align-items: center;
        flex: 1;
        max-width: 360px;
        justify-content: space-between;

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          max-width: 280px;
          height: 48px;
          border-radius: 6px;
          border: 1.5px solid ${({ theme }) => theme.colors.white};
          background-color: ${({ theme }) => theme.colors.white};
          color: ${({ theme }) => theme.colors.black};
          text-decoration: none;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1rem;
          font-weight: bold;
          max-width: 311px;
          transition: all 0.3s;
        }
      }
    }

    &--expert {
      position: relative;
      z-index: 12;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .photo {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 1.5rem;

        img {
          height: auto;
          width: 100%;
        }
      }

      strong {
        letter-spacing: -0.005em;
        font-weight: 600;
        margin-bottom: 0.25rem;
        display: block;
      }

      span {
        font-size: 0.875rem;
        letter-spacing: -0.005em;
        color: #ffffff;
        opacity: 0.8;
      }
    }
  }

  .materials {
    .courses {
      --size: 1;
      --gap: 1rem;

      display: grid;
      grid-template-columns: repeat(var(--size), 1fr);
      grid-gap: var(--gap);

      @media (min-width: 768px) {
        --size: 2;
        --gap: 1rem;
      }

      @media (min-width: 1025px) {
        --size: 4;
        --gap: 1.5rem;
      }
    }
  }

  .biography {
    display: grid;
    grid-template-columns: 1fr 2fr;

    @media only screen and (max-width: 550px) {
      grid-template-columns: 1fr;
    }

    h2 {
      font-size: 2.2em;
      color: #fff;
      margin: 0 0 15px 0;
    }
  }

  .item {
    margin: 1px;
    iframe {
      width: 100%;
      height: 330px;

      @media (min-width: 992px) {
        height: 474px;
      }
    }
  }
`;
