import styled from "styled-components";

export const Container = styled.div`
  .subheader {
    margin-top: 80px;
    padding: 2rem 0px;
    background: #0f1116;

    @media (min-width: 992px) {
      padding: 4.25rem 0px;
      margin-top: 88px;
    }

    &-container {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;

      @media (min-width: 992px) {
        flex-direction: row;
        align-items: center;
      }

      h1 {
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: 2rem;
        line-height: 115%;
        color: ${({ theme }) => theme.colors.text};
        margin-bottom: 2rem;

        @media (min-width: 992px) {
          margin-bottom: 0;
        }

        svg {
          color: ${({ theme }) => theme.colors.primary};
          margin-right: 1rem;
        }
      }

      .filters {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        grid-gap: 1rem;

        @media (min-width: 992px) {
          width: auto;
          flex-direction: row;
          align-items: center;
          grid-gap: 2rem;
        }

        &-search {
          width: 100%;
          @media (min-width: 992px) {
            width: 500px;
          }

          .control-input {
            width: 100%;
            display: flex;
            align-items: center;
            border: 1px solid ${({ theme }) => theme.colors.border};
            border-radius: 10px;
            padding: 1rem;

            input {
              background: transparent;
              border: 0;
              padding: 0;
              height: auto;
              width: 100%;
              color: ${({ theme }) => theme.colors.text};
            }

            svg {
            }
          }
        }

        &-categories {
          width: 100%;

          @media (min-width: 992px) {
            width: auto;
          }

          select {
            width: 100%;
            background: ${({ theme }) => theme.colors.border};
            padding: 1rem;
            border-radius: 10px;
            border: 1px solid ${({ theme }) => theme.colors.border};
            color: ${({ theme }) => theme.colors.white};

            @media (min-width: 992px) {
              width: auto;
            }
          }
        }
      }
    }
  }

  .partners {
    --size: 1;
    --gap: 1rem;

    display: grid;
    grid-template-columns: repeat(var(--size), 1fr);
    grid-gap: var(--gap);

    @media (min-width: 992px) {
      --size: 4;
      --gap: 1.5rem;
    }
  }
`;
