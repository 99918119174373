import httpAxios from "./index";

class HighlightClass {
  resource = "api/destaques";

  list<T = any>() {
    return httpAxios.get<T>(this.resource);
  }
}

const highLightHttp = new HighlightClass();

export default highLightHttp;
