import React, { FC } from "react";
import { Container } from "./styles";
import Skeleton from "react-loading-skeleton";

interface SkeletonVideoCardProps {
  count: number;
  height: number;
  text?: boolean;
}

const SkeletonVideoCard: FC<SkeletonVideoCardProps> = ({
  count,
  height,
  text = false,
}) => {
  return (
    <>
      {Array(count)
        .fill(1)
        .map((item, index) => {
          return (
            <Container>
              <div>
                <Skeleton
                  height={height}
                  baseColor="#202020"
                  highlightColor="#444"
                />
                {text && (
                  <div className="info">
                    <h4>
                      <Skeleton baseColor="#202020" highlightColor="#444" />
                    </h4>
                    <Skeleton baseColor="#202020" highlightColor="#444" />
                  </div>
                )}
              </div>
            </Container>
          );
        })}
    </>
  );
};
export default SkeletonVideoCard;
//
