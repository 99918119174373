import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  background-color: rgba(32, 34, 39, 0.6);

  .card {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    grid-row-gap: 2rem;
    cursor: pointer;
    text-decoration: none;
    border: 0;
    background: none;

    &.small {
      @media (min-width: 992px) {
        height: 500px;
      }
    }

    @media (min-width: 992px) {
      max-width: 1005px;
      flex-direction: row;
    }
  }

  picture {
    position: relative;
    width: 100%;
    overflow: hidden;
    display: block;
    align-items: center;
    justify-content: center;

    &.small {
      @media (min-width: 992px) {
        max-width: 130px;
        height: 100%;
      }
    }

    @media (min-width: 992px) {
      max-width: 240px;
      height: 100%;
    }

    img {
      display: block;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }

    .progress {
      width: 100%;
      height: 5px;
      position: absolute;
      bottom: 0;
      background-color: #86888d;
      margin: auto;
      border-radius: 2px;

      .bar {
        height: 100%;
        border-radius: 2px;
        position: absolute;
        top: 0;
        left: 0;
        background-color: ${({ theme }) => theme.colors.primary};
      }
    }

    span {
      position: absolute;
      bottom: 7px;
      right: 0;
      z-index: 1;
      background: rgba(32, 34, 39, 0.8);
      backdrop-filter: blur(80px);
      padding: 6px;
      font-weight: 600;
      font-size: 12px;
      letter-spacing: -0.005em;
      color: ${({ theme }) => theme.colors.white};
    }
  }

  .info {
    flex: 1;
    padding: 6px 10px;

    .tags {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;

      span {
        display: inline-block;
        padding: 6px 10px;
        background: #202227;
        border-radius: 4px;
        font-weight: 600;
        font-size: 0.875rem;
        color: rgba(255, 255, 255, 0.7);
      }
      .flag {
        display: flex;
        align-items: center;
        margin-left: 1rem;
        font-weight: 600;
        font-size: 1rem;
        letter-spacing: -0.005em;
        color: rgba(255, 255, 255, 0.6);

        svg {
          margin-left: 0.75rem;
        }
      }
    }

    h6 {
      font-size: 1.1rem;
      line-height: 145%;
      letter-spacing: -0.005em;
      font-weight: 600;
      text-align: left;
      color: ${({ theme }) => theme.colors.white};
      margin-bottom: 4px;
    }

    p {
      font-size: 0.8rem;
      line-height: 145%;
      letter-spacing: -0.005em;
      text-align: left;
      color: ${({ theme }) => theme.colors.white};
      opacity: 0.7;
      transition: opacity 0.3s;

      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
`;
