import styled from "styled-components";

export const Container = styled.div`
  .swiper {
    width: 100%;
    height: 100%;

    &-slide {
      background-position: center;
      background-size: cover;

      @media (min-width: 320px) {
        height: 510px;
      }
      @media (min-width: 480px) {
        height: 60vh;
      }
      @media (min-width: 600px) {
        height: 700px;
      }
      @media (min-width: 801px) {
        height: 80vh;
      }
      @media (min-width: 1025px) {
        height: 80vh;
      }
      @media (min-width: 1281px) {
        height: 880px;
      }
      @media (min-width: 1440px) {
        height: 930px;
      }

      @media (min-width: 1920px) {
        height: 1178px;
      }

      img {
        display: block;
        width: 100%;
        background-size: cover !important;

        @media (max-width: 480px) {
          object-position: 70%;
          height: 65vh;
          object-fit: cover;
        }
      }

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, #0f1116 0, transparent 50%),
          linear-gradient(45.72deg, #0f1116 8.71%, rgba(15, 17, 22, 0) 72.99%);
        padding-top: 7rem;
        padding-left: 1.5625rem;
        padding-right: 1.5625rem;

        @media (min-width: 768px) {
          padding-top: 8rem;
        }

        .text {
          span {
            background-image: linear-gradient(
              267.26deg,
              ${({ theme }) => theme.colors.primary} 100%,
              ${({ theme }) => theme.colors.primarylight} 130.41%
            );
            color: transparent;
            -webkit-background-clip: text;
            background-clip: text;
            font-weight: 700;
            font-size: 1.1rem;

            @media (min-width: 768px) {
              font-size: 1.8rem;
            }
          }

          h1 {
            font-size: 2.3rem;
            margin-top: 1rem;
            line-height: 100%;
            max-width: 50rem;
            color: ${({ theme }) => theme.colors.white};

            @media (min-width: 320px) {
              font-size: 1.5rem;
              margin-top: 2.6rem;
            }

            @media (min-width: 480px) {
              font-size: 3.4rem;
              margin-top: 2.6rem;
            }

            @media (min-width: 1025px) {
              font-size: 3.4rem;
            }

            @media (min-width: 1281px) {
              font-size: 3.4rem;
              margin-top: 2.6rem;
            }

            @media (min-width: 1920px) {
              font-size: 4.4rem;
              margin-top: 2.6rem;
            }
          }

          p {
            max-width: 40rem;
            font-size: 1.2rem;
            font-weight: 400;
            margin-top: 1rem;
            line-height: 1.5rem;
            color: ${({ theme }) => theme.colors.white};

            @media (min-width: 320px) {
              font-size: 0.9rem;
              line-height: 1.5rem;
              margin-top: 1.5rem;
            }

            @media (max-width: 1370px) {
              font-size: 0.9rem;
              line-height: 1.4rem;
              margin-bottom: 1rem;
            }
            @media (min-width: 992px) {
              font-size: 1rem;
              margin-top: 1.1rem;
            }

            @media (min-width: 1920px) {
              font-size: 1.2rem;
              margin-top: 2.6rem;
              line-height: 2.5rem;
            }
          }
        }

        .buttons {
          margin-top: 2rem;
          display: flex;
          gap: 10px;

          @media (min-width: 992px) {
            margin-top: 2rem;
            gap: 20px;
          }

          a {
            width: 100%;
            height: 3rem;
            display: flex;
            border-radius: 8px;
            align-items: center;
            justify-content: center;
            transition: all 0.3s;
            cursor: pointer;
            font-weight: 700;
            font-size: 1rem;
            text-align: center;
            text-decoration: none;

            @media (min-width: 992px) {
              width: 12.5rem;
              height: 3rem;
              font-size: 1rem;
            }

            @media (min-width: 1920px) {
              width: 13.5rem;
              height: 3.5rem;
              font-size: 1.2rem;
            }

            &.btn-white {
              background-color: ${({ theme }) => theme.colors.white};
              color: ${({ theme }) => theme.colors.title};

              &:hover {
                background-color: ${({ theme }) => theme.colors.primary};
                color: ${({ theme }) => theme.colors.black};
              }
            }

            &.btn-outline {
              border: 1px solid ${({ theme }) => theme.colors.white};
              color: ${({ theme }) => theme.colors.white};

              &:hover {
                background-color: ${({ theme }) => theme.colors.white};
                color: ${({ theme }) => theme.colors.title};
              }
            }
          }
        }
      }
    }

    &-pagination {
      bottom: 5rem !important;

      @media (min-width: 992px) {
        bottom: 20rem !important;
      }
      @media (min-width: 1360px) {
        bottom: 17rem !important;
      }
      @media (min-width: 1400px) {
        bottom: 20rem !important;
      }
      @media (min-width: 1600px) {
        bottom: 26rem !important;
      }
      @media (min-width: 1900px) {
        bottom: 21rem !important;
      }

      &-bullet {
        height: 0.5rem;
        width: 6rem;
        opacity: 0.4;
        background-color: ${({ theme }) => theme.colors.white};
        margin: 0 3px;
        border-radius: 0;
        transition: opacity 0.3s;

        &.swiper-pagination-bullet-active {
          opacity: 1;
        }
      }
    }
  }
`;
