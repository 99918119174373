declare module "@mui/material/styles" {
  interface PaletteColor {
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    red: string;
    purple: string;
    yellow: string;
  }
}

export const primary = {
  100: "#fefcf2",
  200: "#fdf9e6",
  300: "#fcf7d9",
  400: "#fbf4cd",
  500: "#fbf2c0",
  main: "#f7e582",
  light: "#f9eca7",
  dark: "#aca05b",
  // main: "#3f51b5",
  // light: "#757de8",

  red: "#FF6B93",
  purple: "#A798FF",
  yellow: "#FF9777",
};

export const secondary = {
  100: "#F9F9F9",
  200: "#ECEFF5",
  300: "#E5EAF2", // outline or border
  400: "#fff", // text muted
  500: "#fff", // main text
  main: "#1d2438", // main text
  light: "#F9F9F9",
  red: "#FF6B93",
  purple: "#A798FF",
  yellow: "#FF9777",
};

export const error = {
  main: "#FD396D",
};

export const success = {
  main: "#2CC5BD",
};

export const warning = {
  main: "#FFE91F",
  dark: "#FFD600",
};

export const info = {
  main: "#A798FF",
};

export const text = {
  primary: secondary[500],
  secondary: secondary[400],
  disabled: secondary[300],
};

export const inputText = {
  dark: "#000",
};
