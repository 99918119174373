import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { RiCloseFill, RiUserLine } from "react-icons/ri";

// import { HiOutlineBadgeCheck } from "react-icons/hi";
// import { BiUserCircle, BiCategory } from "react-icons/bi";
// import { MdPassword, MdOutlineCategory } from "react-icons/md";
// import { BsGrid } from "react-icons/bs";

import { Container } from "./styles";
import Logo from "assets/logo.png";
import useAuth from "../../hooks/useAuth";

interface MenuDrawerProps {
  open: boolean;
  onClose: () => void;
}

export const MenuDrawer = ({ open, onClose }: MenuDrawerProps) => {
  const navigate = useNavigate();
  const { signOut, user } = useAuth();

  useEffect(() => {
    let aisde = document.querySelector(".menu-drawer");

    if (open) {
      aisde?.classList.add("show");
    } else {
      aisde?.classList.remove("show");
    }
  }, [open]);

  // const handleRouteDrawer = useCallback(
  //   (route: string) => {
  //     try {
  //       let aisde = document.querySelector(".menu-drawer");
  //
  //       aisde?.classList.remove("show");
  //     } finally {
  //       navigate(route);
  //     }
  //   },
  //   [navigate]
  // );

  // const linkExternal = (url: string | undefined) => {
  //   if (url) {
  //     window.location.href = url;
  //   }
  // };

  const logout = async () => {
    await signOut();
    navigate("/login");
  };
  return (
    <Container className="menu-drawer">
      <div className="overlay"></div>

      <div className="card">
        <header className="card-header">
          <h3>Minha conta</h3>
          {/*<img src={Logo} alt="TTribo.com" loading={"lazy"} />*/}

          <button onClick={onClose}>
            <RiCloseFill />
          </button>
        </header>
        <div className="card-content">
          <div className="card-content--user">
            <picture>
              <RiUserLine />
            </picture>

            <div className="info">
              <span>Olá,</span>
              <h4>{user?.name}</h4>
              <em>{user?.email}</em>
            </div>
          </div>

          {/*<nav className="card-content--menu">*/}
          {/*  <ul>*/}
          {/*    <li className="mobile">*/}
          {/*      <button onClick={() => handleRouteDrawer("/")}>*/}
          {/*        <span>*/}
          {/*          <BsGrid />*/}
          {/*          Home*/}
          {/*        </span>*/}
          {/*        <RiArrowRightSLine />*/}
          {/*      </button>*/}
          {/*    </li>*/}

          {/*    <li className="mobile">*/}
          {/*      <button onClick={() => handleRouteDrawer("/cursos")}>*/}
          {/*        <span>*/}
          {/*          <BiCategory />*/}
          {/*          Cursos*/}
          {/*        </span>*/}
          {/*        <RiArrowRightSLine />*/}
          {/*      </button>*/}
          {/*    </li>*/}

          {/*    <li className="mobile">*/}
          {/*      <button onClick={() => handleRouteDrawer("/parceiros")}>*/}
          {/*        <span>*/}
          {/*          <MdOutlineCategory />*/}
          {/*          Parceiros*/}
          {/*        </span>*/}
          {/*        <RiArrowRightSLine />*/}
          {/*      </button>*/}
          {/*    </li>*/}

          {/*    <li className="mobile">*/}
          {/*      <button*/}
          {/*        onClick={() =>*/}
          {/*          linkExternal(process.env.REACT_APP_URL_ACCOUNT)*/}
          {/*        }*/}
          {/*      >*/}
          {/*        <span>*/}
          {/*          <RiUser6Line />*/}
          {/*          Minha Conta*/}
          {/*        </span>*/}
          {/*        <RiArrowRightSLine />*/}
          {/*      </button>*/}
          {/*    </li>*/}

          {/*    <li className="desktop">*/}
          {/*      <button*/}
          {/*        onClick={() =>*/}
          {/*          window.open("https://account.ttribo.com", "_blank")*/}
          {/*        }*/}
          {/*      >*/}
          {/*        <span>*/}
          {/*          <BiUserCircle />*/}
          {/*          Dados pessoais*/}
          {/*        </span>*/}
          {/*        <RiArrowRightSLine />*/}
          {/*      </button>*/}
          {/*    </li>*/}

          {/*    <li className="desktop">*/}
          {/*      <button*/}
          {/*        onClick={() =>*/}
          {/*          window.open("https://account.ttribo.com/compras", "_blank")*/}
          {/*        }*/}
          {/*      >*/}
          {/*        <span>*/}
          {/*          <HiOutlineBadgeCheck />*/}
          {/*          Compras*/}
          {/*        </span>*/}
          {/*        <RiArrowRightSLine />*/}
          {/*      </button>*/}
          {/*    </li>*/}

          {/*    <li className="desktop">*/}
          {/*      <button*/}
          {/*        onClick={() =>*/}
          {/*          window.open("https://account.ttribo.com/senha", "_blank")*/}
          {/*        }*/}
          {/*      >*/}
          {/*        <span>*/}
          {/*          <MdPassword />*/}
          {/*          Senha*/}
          {/*        </span>*/}
          {/*        <RiArrowRightSLine />*/}
          {/*      </button>*/}
          {/*    </li>*/}
          {/*  </ul>*/}
          {/*</nav>*/}

          <button className="btn-logout" onClick={logout}>
            {" "}
            Desconectar{" "}
          </button>
        </div>
      </div>
    </Container>
  );
};
