import httpAxios from "./index";
import { User } from "../types/Auth";

class AuthClass {
  user() {
    return httpAxios.get("api/user");
  }

  update(user: User) {
    return httpAxios.put("api/customer", user);
  }

  purchases() {
    return httpAxios.get("api/eduzz/purchases");
  }
}

const authHttp = new AuthClass();

export default authHttp;
