import { Link } from "react-router-dom";

import { IoPlay } from "react-icons/io5";

import { Container } from "./styles";
import { CoursesProgressType } from "../../types/Courses";

export const ContinueCourseCard = ({
  course_id,
  thumb,
  title,
  percentage,
  category,
}: CoursesProgressType) => {
  const percentageRound = Math.round(percentage || 0);

  return (
    <Container key={course_id}>
      <Link to={`cursos/details/${course_id}`}>
        <picture>
          <img
            src={`${process.env.REACT_APP_URL_SPACE}/${thumb}`}
            alt={title}
            loading="lazy"
          />
        </picture>
      </Link>

      <div className="info">
        <span>{title}</span>
        <p>{category}</p>

        <Link to={`cursos/details/${course_id}`}>
          <div className="progress-bar">
            <div style={{ width: `${percentageRound}%` }}></div>
          </div>
          <div className="info-progress">
            <button>
              <div className="icon">
                <IoPlay />
              </div>
              <span>continuar</span>
            </button>
            <strong>{percentageRound}%</strong>
          </div>
        </Link>
      </div>
    </Container>
  );
};
