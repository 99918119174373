import { useEffect, useState } from "react";
import { BiCategory } from "react-icons/bi";

//import { CourseCard } from "components/CourseCard";

import { Container } from "./styles";
import { PartnersType } from "../../types/Partners";
import { useParams } from "react-router-dom";
import partnersHttp from "../../repositores/partner";
import { CourseCard } from "../../components/CourseCard";
import SkeletonVideoCard from "../../components/SkeletonVideoCard";
import { Helmet } from "react-helmet-async";
import Vimeo from "@u-wave/react-vimeo";

export const PartnerDetails = (): JSX.Element => {
  const { partnerId } = useParams<{ partnerId: string }>();
  const [partner, setPartner] = useState<PartnersType | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    async function get(id: string) {
      setLoading(true);
      try {
        const { data } = await partnersHttp.get(id);
        setPartner(data.data);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    }
    if (partnerId) {
      get(partnerId);
    }
  }, [partnerId]);

  return (
    <Container>
      {partner?.name && <Helmet title={`${partner?.name} | Trribo.com`} />}
      <section className="partner-header">
        <picture className="partner-header--banner">
          <img
            src={`${process.env.REACT_APP_URL_SPACE}/${partner?.banner}`}
            alt="Parceiro"
            loading="lazy"
          />
        </picture>
        <div className="container">
          <div className="partner-header--text">
            <h1>{partner?.name}</h1>

            <p>{partner?.short_description}</p>
          </div>

          <div className="partner-header--expert">
            <div className="photo">
              <img
                src={`${process.env.REACT_APP_URL_SPACE}/${partner?.avatar}`}
                alt={partner?.name}
                loading="lazy"
              />
            </div>
            <strong>{partner?.name}</strong>
            <span>{partner?.caption}</span>
          </div>
        </div>
      </section>

      <section className="materials section">
        <div className="container-fluid">
          <h3>
            <BiCategory />{" "}
            {partner?.courses && partner?.courses.length > 0
              ? "Cursos"
              : "Em Breve"}
          </h3>

          <div className="courses">
            {loading && <SkeletonVideoCard count={8} height={238} />}
            {!loading &&
              partner?.courses.map((course) => (
                <CourseCard key={course.id} {...course} />
              ))}
          </div>
        </div>
      </section>

      <section className="section">
        <div className="container-fluid">
          <h3>
            <BiCategory />
            Biografia
          </h3>
          <div className="biography">
            <div className="partner-header--text item">
              <h2>{partner?.name}</h2>

              <p>{partner?.description}</p>
            </div>
            <div className="item">
              <div className="player">
                {partner?.video_id && <Vimeo video={partner?.video_id} speed />}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Container>
  );
};
