import styled from "styled-components";

export const Container = styled.div`
  .filters {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    grid-gap: 1rem;
    padding: 1% 1% 0 1%;

    @media (min-width: 992px) {
      width: auto;
      flex-direction: row;
      align-items: center;
      grid-gap: 0.625rem;
    }

    &-search {
      width: 100%;
      @media (min-width: 992px) {
        width: 200px;
      }

      .control-input {
        width: 100%;
        display: flex;
        align-items: center;
        border: 1px solid ${({ theme }) => theme.colors.border};
        border-radius: 10px;
        padding: 0.7rem;

        input {
          background: transparent;
          border: 0;
          padding: 0;
          height: auto;
          width: 100%;
          color: ${({ theme }) => theme.colors.text};
        }

        svg {
        }
      }
    }

    &-categories {
      width: 100%;

      @media (min-width: 992px) {
        width: auto;
      }

      select {
        width: 143px !important;
        background: ${({ theme }) => theme.colors.border};
        padding: 0.5rem;
        border-radius: 10px;
        border: 1px solid ${({ theme }) => theme.colors.border};
        color: ${({ theme }) => theme.colors.white};
        font-size: 0.725rem;

        @media (min-width: 992px) {
          width: auto;
        }
      }
    }
  }
`;
