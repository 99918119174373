import styled, { keyframes } from "styled-components";

const hoverAnimation = keyframes`
  from {
    height: 0;
  }
  to {
    height: 100%;
  }
`;

const hoverTextAnimation = keyframes`
  from {
    bottom: -100px;
  }
  to {
    bottom: 0;
  }
`;

export const Container = styled.div`
  button {
    border: 0px;
    background: none;
    position: relative;
    width: 100%;
    height: 40rem;
    cursor: pointer;
    transition: all 0.3s;
    border-radius: 10px;
    overflow: hidden;

    @media (min-width: 768) {
      height: 30rem;
    }

    &:hover {
      .info {
        animation-name: ${hoverTextAnimation};
        animation-fill-mode: forwards;
        animation-duration: 300ms;

        .description {
          opacity: 1;
          height: 100px;
        }
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: 10px;
    }

    .info {
      width: 100%;
      padding: 1rem;
      bottom: -70px;
      position: absolute;
      z-index: 20;
      background-image: linear-gradient(180deg, transparent, black);

      @media (min-width: 992px) {
        padding: 1vw;
      }

      .category {
        display: block;
        font-weight: 600;
        font-size: 0.75rem;
        line-height: 145%;
        text-align: left;
        letter-spacing: -0.005em;
        color: ${({ theme }) => theme.colors.info};
        display: block;
        margin-bottom: 0.5rem;
      }

      h3 {
        color: ${({ theme }) => theme.colors.white};
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 115%;
        text-align: left;
        margin: 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
      }

      .sub-title {
        font-size: 0.875rem;
        line-height: 145%;
        text-align: left;
        letter-spacing: -0.005em;
        color: ${({ theme }) => theme.colors.white};
        opacity: 0.7;
        transition: opacity 0.3s;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
      }

      .description {
        font-size: 0.875rem;
        line-height: 145%;
        text-align: left;
        letter-spacing: -0.005em;
        color: ${({ theme }) => theme.colors.white};
        opacity: 0.7;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        white-space: normal;
        height: 60px;
        margin-top: 10px;
        opacity: 0;
      }
    }

    .shadow-hover {
      width: 100%;
      height: 100%;
      background-image: linear-gradient(180deg, transparent, #00000055);
      position: absolute;
      bottom: 0;
      left: 0;
      animation-name: ${hoverAnimation};
      animation-fill-mode: forwards;
      animation-duration: 200ms;
      display: none;
      z-index: 1;
    }
  }
`;
