import styled from "styled-components";

export const Container = styled.div`
  .content {
    position: relative;
    z-index: 2;
    margin-top: -4rem;

    @media (min-width: 992px) {
      margin-top: -17rem;
    }
  }
  .section-watchs {
    h3 {
      display: flex;
      align-items: center;
      grid-column-gap: 1rem;
      margin-bottom: 1rem;
      font-size: 1.2rem;
      line-height: 1.8rem;
      color: ${({ theme }) => theme.colors.white};

      @media (min-width: 992px) {
        font-size: 1.8rem;
        line-height: 2.2rem;
      }

      svg {
        color: ${({ theme }) => theme.colors.primary};
      }
    }

    .watchs {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      grid-gap: 1rem;

      @media (min-width: 992px) {
        flex-direction: row;
        grid-gap: 2rem;
      }
    }
  }

  .courses {
    --size: 1;
    --gap: 0.5rem;

    display: grid;
    grid-template-columns: repeat(var(--size), 1fr);
    grid-gap: var(--gap);

    @media (min-width: 768px) {
      --size: 2;
      --gap: 0.5rem;
    }

    @media (min-width: 1025px) {
      --size: 4;
      --gap: 0.5rem;
    }
  }
`;
