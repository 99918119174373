import httpAxios from "./index";

class CourseClass {
  resource = "api/cursos";

  list<T = any>(params = {}) {
    return httpAxios.get<T>(this.resource, {
      params: params,
    });
  }
  get<T = any>(id: string) {
    return httpAxios.get<T>(`${this.resource}/${id}`);
  }

  updateProgress<T = any>(courseId: string, episodeId: string, data: any) {
    return httpAxios.put<T>(
      `${this.resource}/${courseId}/episodio/${episodeId}/updateProgress`,
      data
    );
  }

  subscritos<T = any>() {
    return httpAxios.get<T>(`${this.resource}/subscritos`);
  }

  sendEmail(courseId: string, data: any) {
    return httpAxios.post(`${this.resource}/${courseId}/send-email`, data);
  }

  episodiosProgress(courseId: string) {
    return httpAxios.get(`${this.resource}/${courseId}/episodios/progress`);
  }
}

const courseHttp = new CourseClass();

export default courseHttp;
