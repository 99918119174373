import styled from "styled-components";

export const Container = styled.div`
    .info {
      h4 {
        margin-top: 20px;
        height: 40px;
        font-size: 1.3rem;
        line-height: 1.5rem;
        text-align: left;
        color: ${({ theme }) => theme.colors.white};
      }

      p {
        margin-top: 0.6rem;
        opacity: 0.8;
        font-size: 1rem;
        text-align: left;
        color: ${({ theme }) => theme.colors.white};
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
`;
