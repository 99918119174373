import { useEffect, useState } from "react";

import { SliderHero } from "components/SliderHero";
import { Container } from "./styles";
import highLightHttp from "../../repositores/highlight";
import { HighLightsType } from "../../types/HighLights";
import SkeletonBanner from "../../components/SkeletonBanner";
import { CoursesProgressType, CoursesType } from "../../types/Courses";
import { ContinueCourseCard } from "../../components/ContinueCourseCard";
import { CoursesExtras } from "../../components/CoursesExtras";
import SkeletonVideoCard from "../../components/SkeletonVideoCard";
import { CourseCard } from "../../components/CourseCard";
import courseHttp from "../../repositores/course";
import useAuth from "../../hooks/useAuth";
import { MdOutlineCategory } from "react-icons/md";
import userHttp from "../../repositores/user";

export const Dashboard = (): JSX.Element => {
  const { user } = useAuth();

  const [highLights, setHighLights] = useState<HighLightsType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [coursesTrribo, setCoursesTtribo] = useState<CoursesType[]>([]);
  const [dataKeepWatching, setDataKeepWatching] = useState<
    CoursesProgressType[]
  >([]);

  async function getAll() {
    setLoading(true);
    try {
      const [dataHighLights, coursesTtribo, dataKeepWatching] =
        await Promise.all([
          highLightHttp.list(),
          courseHttp.list({ access: "T" }),
          userHttp.keepWatching(),
        ]);
      setHighLights(dataHighLights.data.data);
      setCoursesTtribo(coursesTtribo.data.data);
      setDataKeepWatching(dataKeepWatching.data.data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getAll();
  }, []);

  return (
    <Container>
      {loading ? <SkeletonBanner /> : <SliderHero data={highLights} />}
      {/*{loading && <SkeletonBanner />}*/}
      {/*{!loading && <SliderHero data={highLights} />}*/}

      <div className="content">
        <CoursesExtras />
        {/*<RecentlyAddedSection />*/}
        <div className="container-fluid">
          {dataKeepWatching.length > 0 && (
            <section className="section-watchs">
              <h3>Continuar assistindo</h3>

              <div className="watchs">
                {dataKeepWatching.map((course) => (
                  <ContinueCourseCard key={course.course_id} {...course} />
                ))}
              </div>
            </section>
          )}
          <section className="section">
            <div className="container-fluid">
              <h3>
                <MdOutlineCategory /> Bônus TTribo+
              </h3>
            </div>
          </section>

          <div className="courses">
            {loading ? (
              <SkeletonVideoCard count={8} height={650} />
            ) : (
              <>
                {coursesTrribo.length > 0 ? (
                  coursesTrribo.map((course) => (
                    <CourseCard
                      key={course.id}
                      {...course}
                      inactive={!user?.courses_actives}
                    />
                  ))
                ) : (
                  <p>Nenhum curso encontrado. :-/</p>
                )}
              </>
            )}
          </div>

          {/*<div className="courses">*/}
          {/*  {categoriesByCourses.map((category) => {*/}
          {/*    return category.courses && category.courses?.length > 0 ? (*/}
          {/*      <section key={category.id} className="section">*/}
          {/*        <h3>*/}
          {/*          <BiCategory /> {category.title}*/}
          {/*        </h3>*/}

          {/*        {category.courses && (*/}
          {/*          <CarrouselCourses data={category.courses} />*/}
          {/*        )}*/}
          {/*      </section>*/}
          {/*    ) : null;*/}
          {/*  })}*/}
          {/*</div>*/}
        </div>
      </div>
    </Container>
  );
};
