import { useEffect, useState } from "react";
import { BiCategory } from "react-icons/bi";

import { CourseCard } from "components/CourseCard";

import { Container } from "./styles";
import { CoursesType } from "../../types/Courses";
import courseHttp from "../../repositores/course";
import "react-loading-skeleton/dist/skeleton.css";
import SkeletonVideoCard from "../../components/SkeletonVideoCard";
import { Helmet } from "react-helmet-async";
import { Filters } from "../../components/Filters";
import { useDebounce } from "use-debounce";
import useAuth from "../../hooks/useAuth";

export const CoursesTtriboMais = (): JSX.Element => {
  const [courses, setCourses] = useState<CoursesType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [category, setCategory] = useState<string>("");
  const { user } = useAuth();

  const [debouncedSearch] = useDebounce(search, 500, { leading: true });

  async function getCourses(search: string, categoryId: string) {
    setLoading(true);
    try {
      const { data } = await courseHttp.list({
        title: search,
        category: categoryId,
        access: "T",
      });

      setCourses(data.data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getCourses(debouncedSearch, category);
  }, [debouncedSearch, category]);

  return (
    <Container>
      <Helmet title={"Cursos"} />
      <header className="subheader">
        <div className="container-fluid">
          <div className="subheader-container">
            <h1>
              <BiCategory /> Ttribo+
            </h1>
          </div>
        </div>
      </header>

      <Filters
        search={search}
        setSearch={setSearch}
        category={category}
        setCategory={setCategory}
      />

      <section className="section">
        <div className="container-fluid">
          <div className="courses">
            {loading ? (
              <div>
                <SkeletonVideoCard count={8} height={650} />
              </div>
            ) : courses.length > 0 ? (
              courses.map((course) => (
                <CourseCard
                  key={course.id}
                  {...course}
                  inactive={!user?.courses_actives}
                />
              ))
            ) : (
              <div>"Nenhum curso encontrado. :-/"</div>
            )}
          </div>
        </div>
      </section>
    </Container>
  );
};
