import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

import { FaPlay } from "react-icons/fa";
import {
  BsJournalBookmarkFill,
  BsDownload,
  BsLink,
  BsForward,
} from "react-icons/bs";
import { IoIosHelpCircle } from "react-icons/io";
import { BiSend } from "react-icons/bi";

import Skeleton from "react-loading-skeleton";

import { EpisodeCard } from "components/EpisodeCard";

import { Container } from "./styles";
import { CoursesType } from "../../types/Courses";
import courseHttp from "../../repositores/course";

import SkeletonEpisodeCard from "../../components/SkeletonEpisodioCard";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import useAuth from "../../hooks/useAuth";
import { EpisodeProgressType } from "../../types/Episode";
import Loading from "../../components/Loading";

export const CourseDetails = (): JSX.Element => {
  const { courseId } = useParams<{ courseId: string }>();
  const [course, setCourse] = useState<CoursesType | null>(null);
  const [progressEpisodios, setProgressEpisodios] = useState<
    EpisodeProgressType[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const { user } = useAuth();

  const [loadingContact, setLoadingContact] = useState<boolean>(false);

  const { register, handleSubmit, reset } = useForm<{ message: string }>({
    defaultValues: {
      message: "",
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    async function getCourse() {
      setLoading(true);
      if (courseId) {
        try {
          const [course, progress] = await Promise.all([
            courseHttp.get(courseId),
            courseHttp.episodiosProgress(courseId),
          ]);
          setCourse(course.data.data);
          setProgressEpisodios(progress.data);
        } catch (e) {
          console.log(e);
        } finally {
          setLoading(false);
        }
      }
    }
    getCourse();
  }, [courseId]);

  /**
   * Close snackbar
   * @param event
   * @param reason
   */
  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  /**
   * Submit form dúvida
   * @param data
   */
  const onSubmit = async (data: any) => {
    if (courseId) {
      setLoadingContact(true);
      try {
        await courseHttp.sendEmail(courseId, data);
        setOpenSnackbar(true);
        reset();
      } catch (e) {
        console.log(e);
      } finally {
        setLoadingContact(false);
      }
    }
  };

  return (
    <Container>
      {course?.title && <Helmet title={course?.title} />}
      <section className="course-header">
        <picture
          className="course-header--banner"
          style={{
            background: `url(${process.env.REACT_APP_URL_SPACE}/${course?.banner})`,
          }}
        >
          {/*<img*/}
          {/*  src={`${process.env.REACT_APP_URL_SPACE}/${course?.banner}`}*/}
          {/*  alt={course?.title}*/}
          {/*/>*/}
        </picture>
        <div className="container">
          <div className="course-header--text">
            <h1>
              {course?.title || (
                <Skeleton baseColor="#202020" highlightColor="#444" />
              )}
            </h1>

            <p>
              {course?.description || (
                <Skeleton baseColor="#202020" highlightColor="#444" />
              )}
            </p>

            <div>
              {course?.episodes && course?.episodes.length > 0 && (
                <Link
                  to={`/cursos/watch/${course?.id}/${course?.episodes[0].id}`}
                  className={loading ? "graydark" : "white"}
                >
                  {!loading && (
                    <>
                      <FaPlay />
                      Assistir agora
                    </>
                  )}
                </Link>
              )}
            </div>
          </div>
          <a
            href={course?.partner?.link || "#"}
            style={{ textDecorationLine: "none", color: "inherit" }}
            rel={"noreferrer"}
            target={"_blank"}
          >
            <div className="course-header--expert">
              <div className="photo">
                <img
                  src={`${process.env.REACT_APP_URL_SPACE}/${course?.partner?.avatar}`}
                  alt={course?.partner?.name}
                  loading="lazy"
                />
              </div>
              <strong>{course?.partner?.name}</strong>
              <span>{course?.partner?.caption}</span>
            </div>
          </a>
        </div>
      </section>

      <section className="materials">
        <div className="container">
          <div className="materials-episodes">
            <h3>Episódios</h3>

            <div className="episodes">
              {loading && (
                <SkeletonEpisodeCard count={6} height={130} text={false} />
              )}
              {!loading &&
                course?.episodes?.map((episode, index) => {
                  let progress = 0;
                  const find = progressEpisodios.find(
                    (element) => element.episode_id === episode.id
                  );
                  if (find !== undefined) {
                    progress = find.percent;
                  }
                  let inactive;

                  if (!episode.public) {
                    if (course?.access === "T") {
                      inactive = !user?.courses_actives;
                    } else {
                      inactive = !user?.courses_actives_ids.includes(
                        courseId || ""
                      );
                    }
                  }

                  return (
                    <EpisodeCard
                      key={episode.id}
                      progress={progress}
                      inactive={inactive}
                      {...episode}
                      index={index}
                    />
                  );
                })}
            </div>
          </div>

          <div className="materials-help">
            {((course?.file_courses && course?.file_courses?.length > 0) ||
              (course?.links && course?.links?.length > 0)) && (
              <div className="materials-help--complementary">
                <h3>Materiais complementares</h3>
                <ul>
                  {course?.file_courses?.map((file) => (
                    <li key={file.id}>
                      <a
                        href={`${process.env.REACT_APP_URL_SPACE}/${file.file}`}
                        download
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        <div>
                          <BsJournalBookmarkFill />
                          <strong>{file.title}</strong>
                        </div>

                        <BsDownload />
                      </a>
                    </li>
                  ))}
                  {course?.links?.map((link) => (
                    <li key={link.id}>
                      <a href={link.link} target={"_blank"} rel="noreferrer">
                        <div>
                          <BsLink />
                          <strong>{link.title}</strong>
                        </div>
                        <BsForward />
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <div className="materials-help--question">
              <IoIosHelpCircle />
              <h4>Envie uma dúvida</h4>
              <p>
                Ficou com uma dúvida sobre o conteúdo? Envia para gente! Estamos
                preparados para te responder.
              </p>
              <label htmlFor="question">Escreva sua dúvida</label>
              <form onSubmit={handleSubmit(onSubmit)}>
                <textarea {...register("message", { required: true })} />
                <button type="submit" disabled={loadingContact}>
                  {loadingContact ? <Loading /> : <BiSend />}
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Dúvida enviadas com sucesso
        </Alert>
      </Snackbar>
    </Container>
  );
};
