import { Navigation, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";

import { MdOutlineCategory } from "react-icons/md";

import { Container } from "./styles";

import { useEffect, useState } from "react";

import { CoursesType } from "../../types/Courses";
import courseHttp from "../../repositores/course";
import { CourseCard } from "../CourseCard";

export const CoursesExtras = () => {
  const [courses, setCourses] = useState<CoursesType[]>([]);

  async function getCourses() {
    try {
      const { data } = await courseHttp.subscritos();
      setCourses(data.data);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    getCourses();
  }, []);

  if (courses.length > 0) {
    return (
      <Container className="section padding-recently">
        <div className="container-fluid">
          <h3>
            <MdOutlineCategory /> Cursos
          </h3>

          <Swiper
            modules={[Navigation, Scrollbar, A11y]}
            navigation
            grabCursor
            scrollbar={{ draggable: true }}
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 0,
                centeredSlides: true,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 25,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
            }}
          >
            {courses.map((row) => (
              <SwiperSlide key={row.id}>
                <CourseCard {...row} />
              </SwiperSlide>
            ))}
            {/*{partners.map((partner, indexItem) => (*/}
            {/*  <SwiperSlide key={indexItem}>*/}
            {/*    <PartnerCard {...partner} />*/}
            {/*  </SwiperSlide>*/}
            {/*))}*/}
          </Swiper>
        </div>
      </Container>
    );
  } else {
    return null;
  }
};
