import { AppProvider } from "./contexts";
import { Routes } from "./routes";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import GlobalStyles from "./themes/GlobalStyle";
import React from "react";
import { ukoTheme } from "./themeMui";

const appTheme = ukoTheme({
  theme: "light",
  direction: "ltr",
  responsiveFontSizes: true,
});

const App = (): JSX.Element => {
  return (
    <HelmetProvider>
      <AppProvider>
        <Helmet>
          <title>TTribo.com</title>
          <link rel="canonical" href="https://app.ttribo.com/" />
        </Helmet>
        <ThemeProvider theme={appTheme}>
          <CssBaseline />
          <Routes />
        </ThemeProvider>
        <GlobalStyles />
      </AppProvider>
    </HelmetProvider>
  );
};

export default App;
