import * as React from "react";

import useAuth from "../../hooks/useAuth";
import httpAxios from "../../repositores";
import { REDIRECT_LOGIN } from "../../config/constants";

interface AuthGuardType {
  children: React.ReactNode;
}
const UNAUTHORIZED = 401;

// For routes that can only be accessed by authenticated users
function AuthGuard({ children }: AuthGuardType) {
  const { isAuthenticated, isInitialized, signOut } = useAuth();

  // Intercepta se o status retorna 401
  httpAxios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === UNAUTHORIZED) {
        signOut();
        return Promise.reject();
      }

      return Promise.reject(error);
    }
  );

  if (isInitialized && !isAuthenticated) {
    if (REDIRECT_LOGIN) {
      window.location.href = REDIRECT_LOGIN;
    }
  }
  if (isAuthenticated) {
    return <React.Fragment>{children}</React.Fragment>;
  }
  return null;
}

export default AuthGuard;
