import { useCallback, useState } from "react";
import Player, { ProgressEvent } from "@u-wave/react-vimeo";
import courseHttp from "../../repositores/course";
import useInterval from "../../hooks/useInterval";

interface VimeoProps {
  videoId: string;
  courseId: string;
  episodeId: string;
}

export const VimeoPlayer = ({ videoId, courseId, episodeId }: VimeoProps) => {
  const [videoTracker, setVideoTraker] = useState({
    seconds: 0,
    percent: 0,
    duration: 0,
    lastUpdate: 1,
    lastSend: 0,
  });
  const [delay, setDelay] = useState<number | null>(null);

  useInterval(() => {
    updateProgress();
  }, delay);

  const vimeoPlayProgressEvent = useCallback(
    (data: ProgressEvent) => {
      let currentPercent = Math.ceil(data.percent * 100);

      if (currentPercent === videoTracker.percent) {
        return;
      }
      let timestamp = new Date().getTime();
      timestamp = Math.floor(timestamp / 1000);
      setVideoTraker((prevState) => {
        return {
          ...prevState,
          ...data,
          percent: currentPercent,
          lastUpdate: timestamp,
        };
      });
    },
    [videoTracker.percent]
  );

  const updateProgress = async () => {
    if (videoTracker.lastUpdate === videoTracker.lastSend) {
      return;
    }

    if (courseId && episodeId) {
      await courseHttp.updateProgress(courseId, episodeId, {
        percent: videoTracker.percent,
        seconds: videoTracker.seconds,
      });
    }

    setVideoTraker((prevState) => {
      return {
        ...prevState,
        lastSend: prevState.lastUpdate,
      };
    });
  };

  return (
    <Player
      video={videoId}
      speed
      onProgress={(e) => {
        vimeoPlayProgressEvent(e);
      }}
      onPlay={() => {
        setDelay(30000);
        updateProgress();
      }}
      onPause={() => {
        setDelay(null);
        updateProgress();
      }}
    />
  );
};
