import { Navigation, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";

import { Container } from "./styles";

import { EpisodeProgressType } from "../../types/Episode";
import { EpisodeCardPlayer2 } from "../EpisodeCardPlayer2";
import { CoursesType } from "../../types/Courses";
import useAuth from "../../hooks/useAuth";

interface CarrouselEpisodesProps {
  course: CoursesType;
  episodesProgress: EpisodeProgressType[];
}

export const CarrouselEpisodes = ({
  course,
  episodesProgress,
}: CarrouselEpisodesProps) => {
  const { user } = useAuth();
  return (
    <Container>
      <Swiper
        modules={[Navigation, Scrollbar, A11y]}
        navigation
        onNavigationShow={(swiper) => console.log(swiper)}
        grabCursor
        scrollbar={{ draggable: true }}
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 0,
            centeredSlides: true,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 25,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
        }}
      >
        {course?.episodes?.map((episode, indexItem) => {
          let progress = 0;
          const find = episodesProgress.find(
            (element) => element.episode_id === episode.id
          );
          if (find !== undefined) {
            progress = find.percent;
          }

          let inactive = false;

          if (!episode.public) {
            if (course?.access === "T") {
              inactive = !user?.courses_actives;
            } else {
              inactive = !user?.courses_actives_ids.includes(course.id || "");
            }
          }

          return (
            <SwiperSlide key={indexItem}>
              <EpisodeCardPlayer2
                {...episode}
                inactive={inactive}
                progress={progress}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Container>
  );
};
