import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Container } from "./styles";

export const SkeletonBanner = () => {
  return (
    <Container>
      <div className="swiper-slide">
        <div className="overlay">
          <div className="text">
            <SkeletonTheme baseColor="#202020" highlightColor="#444">
              <span>
                <Skeleton />
              </span>
              <h3>
                <Skeleton />
              </h3>
              <p>
                <Skeleton count={3} />
              </p>
            </SkeletonTheme>
          </div>

          <div className="buttons">
            <a className="btn-color" href="/">
              #
            </a>

            <a className="btn-color" href="/">
              #
            </a>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default SkeletonBanner;
