import httpAxios from "./index";

class CategoryClass {
  resource = "api/categorias";

  list<T = any>(params = {}) {
    return httpAxios.get<T>(this.resource, {
      params: params,
    });
  }
}

const categoryHttp = new CategoryClass();

export default categoryHttp;
