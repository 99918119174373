import styled from "styled-components";

export const Container = styled.div`
  margin-top: 0;
  padding-top: 6rem;
  padding-bottom: 6rem;

  @media (min-width: 992px) {
    margin-top: 5.4375rem;
    padding-top: 5.1875rem;
    padding-bottom: 6rem;
  }

  .watch {
    .container {
      transition: all ease-in-out 0.2s;
      display: grid;
      gap: 15px;
      grid-template-columns: 1fr;
      grid-template-areas:
        "title title"
        "player player"
        "class class"
        "resume resume"
        "center center";

      @media (min-width: 992px) {
        max-width: 1300px;
        gap: 30px 20px;
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
          "title title"
          "player player"
          "class class"
          "resume center";
      }

      @media (min-width: 1400px) {
        margin-left: 3%;
        margin-right: auto;
      }
      @media (min-width: 1680px) {
        max-width: 1600px;
        margin-left: 0.7%;
      }

      @media (min-width: 1920px) {
        margin-left: 5%;
      }

      .title {
        grid-area: title;
        position: relative;
        z-index: 1;
        font-weight: 600;

        button {
          display: flex;
          align-items: center;
          text-decoration: none;
          border: 0;
          background: none;

          svg {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 2.375rem;
            background: #202227;
            font-size: 1.4rem;
            color: ${({ theme }) => theme.colors.primary};
            margin-right: 2rem;

            @media (min-width: 992px) {
              font-size: 1.4rem;
              margin-right: 2.375rem;
            }
          }

          h2 {
            font-weight: bold;
            font-size: 1.3rem;
            line-height: 115.5%;
            color: ${({ theme }) => theme.colors.white};

            @media (min-width: 992px) {
              font-size: 2rem;
              line-height: 115.5%;
            }
          }
        }
      }

      .player {
        grid-area: player;
        @media (max-width: 1024px) {
          margin-right: 3%;
        }

        .video {
          position: relative;
          left: 2%;
          margin-bottom: 32px;
          width: 100%;

          iframe {
            width: 100%;
            height: 330px;

            @media (min-width: 992px) {
              height: 474px;
            }
          }
        }
      }

      .payment {
        padding: 30px;
        min-height: 50vh;
        line-height: 50px;

        h2 {
          color: ${({ theme }) => theme.colors.white};
        }
        
        h3 {
          color: ${({ theme }) => theme.colors.primary};
        }
        
        .buttons {
          margin-top: 2rem;
          display: flex;
          gap: 10px;

          @media (min-width: 992px) {
            margin-top: 2rem;
            gap: 20px;
          }

          a {
            width: 100%;
            height: 3rem;
            display: flex;
            border-radius: 8px;
            align-items: center;
            justify-content: center;
            transition: all 0.3s;
            cursor: pointer;
            font-weight: 700;
            font-size: 1rem;
            text-align: center;
            text-decoration: none;

            @media (min-width: 992px) {
              width: 12.5rem;
              height: 3rem;
              font-size: 1rem;
            }

            @media (min-width: 1920px) {
              width: 13.5rem;
              height: 3.5rem;
              font-size: 1.2rem;
            }

            &.btn-white {
              background-color: ${({ theme }) => theme.colors.primary};
              color: ${({ theme }) => theme.colors.black};
              border: 1px solid ${({ theme }) => theme.colors.white};
              

            &:hover {
              background-color: ${({ theme }) => theme.colors.black};
              color: ${({ theme }) => theme.colors.white};  
              }
            }

            &.btn-outline {
              border: 1px solid ${({ theme }) => theme.colors.white};
              color: ${({ theme }) => theme.colors.white};

              &:hover {
                background-color: ${({ theme }) => theme.colors.white};
                color: ${({ theme }) => theme.colors.title};
              }
            }
          }
        }
      }
    }
      }

      .class-list {
        grid-area: class;
        overflow-x: hidden;
        margin-bottom: 25px;

        @media (min-width: 992px) {
          max-height: 32rem;
          display: block;
          position: relative;
          left: 1.725%;
        }
        @media (max-width: 1680px) {
          max-height: 32rem;
          display: block;
          position: relative;
        }

        .wrapper-class {
          .top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 48px;

            h4 {
              color: #86888d;
              font-weight: 600;
              font-size: 1.25rem;
              line-height: 115%;
            }
          }

          .all {
            margin-bottom: 2rem;

            @media (min-width: 992px) {
              overflow-y: scroll;
              max-height: 27rem;
              scrollbar-color: transparent transparent;
              ::-webkit-scrollbar {
                width: auto;
              }
            }

            .episodes {
              display: flex;
              flex-direction: column;
              grid-row-gap: 3rem;
            }
          }
        }
      }

      .resume {
        grid-area: resume;

        .class-description {
          h3 {
            font-weight: bold;
            font-size: 1.5rem;
            line-height: 115%;
            color: #86888d;
            margin-bottom: 1rem;
          }

          p {
            letter-spacing: -0.005em;
            line-height: 145%;
          }
        }

        .release-date {
          display: flex;
          align-items: center;
          margin-top: 3.1875rem;

          span {
            color: rgba(255, 255, 255, 0.7);
            strong {
              font-size: 1.125rem;
            }
          }
        }
      }

      .center {
        grid-area: center;
        margin-top: 2rem;

        @media (min-width: 992px) {
          margin-top: 0;
          display: block;
          position: relative;
          left: 16%;
        }

        @media (max-width: 1680px) {
          margin-top: 0;
          display: block;
          position: relative;
          left: 5%;
        }

        .center-image-name {
          display: flex;
          align-items: center;

          .foto {
            img {
              border-radius: 50%;
              width: 70px;
              height: 70px;
              -o-object-fit: cover;
              object-fit: cover;
            }
          }

          .info {
            margin-left: 18px;
            strong {
              display: block;
            }
            p {
              display: block;
              color: #fff;
              opacity: 0.7;
            }
          }
        }

        .center-description {
          padding-top: 18px;
          font-size: 16px;
          line-height: 18px;

          strong {
            color: #fff;
            opacity: 0.7;
            font-size: 18px;
            display: block;
            margin: 0;
          }
        }
      }
    }
  }
`;
