import httpAxios from "./index";

class UserClass {
  resource = "api/user";

  keepWatching<T = any>() {
    return httpAxios.get<T>(`${this.resource}/keep-watching`);
  }
}

const userHttp = new UserClass();

export default userHttp;
