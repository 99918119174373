import styled from "styled-components";

export const Container = styled.div`
  .subheader {
    margin-top: 80px;
    padding: 2rem 0px;
    background: #0f1116;

    @media (min-width: 992px) {
      padding: 4.25rem 0px;
      margin-top: 88px;
    }

    &-container {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;

      @media (min-width: 992px) {
        flex-direction: row;
        align-items: center;
      }

      h1 {
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: 2rem;
        line-height: 115%;
        color: ${({ theme }) => theme.colors.text};
        margin-bottom: 2rem;

        @media (min-width: 992px) {
          margin-bottom: 0;
        }

        svg {
          color: ${({ theme }) => theme.colors.primary};
          margin-right: 1rem;
        }
      }
    }
  }

  .courses {
    --size: 1;
    --gap: 0.5rem;

    display: grid;
    grid-template-columns: repeat(var(--size), 1fr);
    grid-gap: var(--gap);

    @media (min-width: 768px) {
      --size: 2;
      --gap: 0.5rem;
    }

    @media (min-width: 1025px) {
      --size: 4;
      --gap: 0.5rem;
    }
  }
`;
