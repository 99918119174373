import { useNavigate } from "react-router-dom";

import { Container } from "./styles";
import { PartnersType } from "../../types/Partners";

export const PartnerCard = ({
  id,
  name,
  caption,
  image,
  description,
}: PartnersType) => {
  const navigate = useNavigate();

  return (
    <Container key={id}>
      <button
        type="button"
        onClick={() => navigate(`/parceiros/details/${id}`)}
      >
        <img
          src={`${process.env.REACT_APP_URL_SPACE}/${image}`}
          alt={name}
          loading="lazy"
        />

        <div className="info">
          {/*<div className="category">{category}</div>*/}
          <h3>{name}</h3>
          <div className="sub-title">{caption}</div>
          <div className="description">{description}</div>
        </div>

        <div className="shadow-hover"></div>
      </button>
    </Container>
  );
};
