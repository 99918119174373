export const secToTime = (seg: number, type = 1): string => {
  const hora = Math.trunc(seg / 3600)
    .toString()
    .padStart(2, "0");
  const minuto = Math.floor((seg % 3600) / 60)
    .toString()
    .padStart(2, "0");
  const segundo = ((seg % 3600) % 60).toString().padStart(2, "0");

  switch (type) {
    case 1:
      return `${hora}h ${minuto}m `;
    case 2:
      return `${hora}h ${minuto}m ${segundo}s`;
    case 3:
      return `${hora}:${minuto}:${segundo}`;
    case 4:
      return `${minuto}:${segundo}`;
    case 5:
      return `${hora}:${minuto}`;
    case 6:
      return hora;
    default:
      return "";
  }
};
