import { useEffect, useState } from "react";
import { MdOutlineCategory } from "react-icons/md";

import { PartnerCard } from "components/PartnerCard";

import { Container } from "./styles";
import { PartnersType } from "../../types/Partners";
import partnersHttp from "../../repositores/partner";
import SkeletonVideoCard from "../../components/SkeletonVideoCard";
import { Helmet } from "react-helmet-async";

export const Partners = (): JSX.Element => {
  const [partners, setPartners] = useState<PartnersType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  async function getPartners() {
    setLoading(true);
    try {
      const { data } = await partnersHttp.list();
      setPartners(data.data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getPartners();
  }, []);

  return (
    <Container>
      <Helmet title={"Parceiros"} />
      <header className="subheader">
        <div className="container-fluid">
          <div className="subheader-container">
            <h1>
              <MdOutlineCategory /> Parceiros
            </h1>
          </div>
        </div>
      </header>

      <section className="section">
        <div className="container-fluid">
          <div className="partners">
            {loading && <SkeletonVideoCard count={8} height={600} />}

            {!loading &&
              partners.map((partner) => (
                <PartnerCard key={partner.id} {...partner} />
              ))}
          </div>
        </div>
      </section>
    </Container>
  );
};
