import styled from "styled-components";

export const Container = styled.div`
  .course-header {
    position: relative;
    width: 100%;
    height: 65vh;
    padding-top: 6rem;

    @media (min-width: 320px) {
      height: 510px;
    }

    @media (min-width: 480px) {
      height: 60vh;
    }

    @media (min-width: 600px) {
      height: 700px;
    }

    @media (min-width: 801px) {
      height: 80vh;
    }

    @media (min-width: 1025px) {
      height: 80vh;
    }

    @media (min-width: 1281px) {
      height: 880px;
    }
    @media (min-width: 1440px) {
      height: 700px;
    }

    @media (min-width: 1920px) {
      height: 45rem;
    }
    &--banner {
      width: 100%;
      height: 100%;

      @media (max-width: 1440px) {
        background-size: cover !important;
        background-position: right 0 !important;
      }

      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;

      &:before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        pointer-events: none;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.5) 0%,
          rgba(0, 0, 0, 0) 40.58%,
          #000000 90.57%
        );
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    .container {
      position: relative;
      z-index: 2;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      box-sizing: border-box;
      flex-direction: column;

      @media (min-width: 992px) and (max-width: 1920px) {
        flex-direction: row;
      }
    }

    &--text {
      flex: 1;
      max-width: 632px;

      h1 {
        margin-top: 1rem;
        margin-bottom: 1rem;
        font-size: 2.5rem;
        line-height: 115%;
        font-weight: bold;
        color: ${({ theme }) => theme.colors.white};

        @media (min-width: 992px) {
          margin-top: 1.5rem;
          margin-bottom: 1rem;
          font-size: 3.5rem;
          line-height: 115%;
        }
      }

      p {
        max-width: 488px;
        margin-bottom: 3rem;
        line-height: 150%;
        color: ${({ theme }) => theme.colors.white};
      }

      > div {
        display: flex;
        align-items: center;
        flex: 1;
        max-width: 360px;
        justify-content: space-between;

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          max-width: 280px;
          height: 48px;
          border-radius: 6px;
          text-decoration: none;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1rem;
          font-weight: bold;
          max-width: 311px;
          transition: all 0.3s;
        }
      }
    }

    .white {
      background-color: ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => theme.colors.black};
      border: 1.5px solid ${({ theme }) => theme.colors.white};
    }

    .graydark {
      background-color: ${({ theme }) => theme.colors.graydark};
      color: ${({ theme }) => theme.colors.graydark};
    }

    &--expert {
      position: relative;
      z-index: 12;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-top: 1rem;

      @media (min-width: 992px) {
        margin-top: 0;
      }

      .photo {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 1.5rem;

        img {
          height: auto;
          width: 100%;
        }
      }

      strong {
        letter-spacing: -0.005em;
        font-weight: 600;
        margin-bottom: 0.25rem;
        display: block;
      }

      span {
        font-size: 0.875rem;
        letter-spacing: -0.005em;
        color: ${({ theme }) => theme.colors.white};
        opacity: 0.8;
      }
    }
  }

  .materials {
    padding-bottom: 8rem;
    margin-top: 4rem;
    margin-left: 2%;
    margin-right: 2%;

    @media (min-width: 992px) {
      margin-top: 0;
    }

    .container {
      display: flex;
      justify-content: space-between;
      align-item: center;
      flex-direction: column-reverse;
      grid-gap: 2rem;

      @media (max-width: 991px) {
        flex-direction: column;
        grid-gap: 0;
      }
      @media (min-width: 992px) {
        flex-direction: row;
        grid-gap: 0;
      }
      @media (min-width: 1500px) {
        width: 1500px;
        margin-left: 5%;
      }
      @media (min-width: 1800px) {
        width: 1500px;
        margin-left: 10%;
      }
    }

    &-episodes {
      @media (min-width: 992px) {
        width: 800px;
      }
      h3 {
        color: #86888d;
        margin-bottom: 2rem;
      }

      .episodes {
        display: flex;
        flex-direction: column;
        grid-row-gap: 2rem;
        width: 100%;
      }
    }

    &-help {
      display: flex;
      flex-direction: column;
      margin-right: 4%;

      @media (min-width: 992px) {
        width: 400px;
        margin-left: 5%;
      }

      &--complementary {
        h3 {
          color: #86888d;
          margin-bottom: 2rem;
        }

        ul {
          padding: 0;
          margin-bottom: 1.5rem;
          list-style-type: none;

          li {
            border-top: 1px solid ${({ theme }) => theme.colors.border};

            &:last-of-type {
              border-bottom: 1px solid ${({ theme }) => theme.colors.border};
            }

            a {
              padding: 1.5rem 0px;
              display: flex;
              justify-content: space-between;
              text-decoration: none;
              font-size: 1rem;
              transition: all 0.3s ease-in-out;

              &:hover {
                svg,
                strong {
                  color: ${({ theme }) => theme.colors.primary};
                }
              }

              div {
                display: flex;
                align-items: flex-start;
                flex: 1;
                grid-column-gap: 1rem;

                > svg {
                  font-size: 1.2rem;
                  color: ${({ theme }) => theme.colors.primary};
                }

                > strong {
                  display: block;
                  font-size: 1rem;
                  color: ${({ theme }) => theme.colors.white};
                }
              }

              > svg {
                color: ${({ theme }) => theme.colors.white};
              }
            }
          }
        }
      }

      &--question {
        padding: 2.5rem 2rem;
        background-color: #0f1116;
        border-radius: 6px;

        > svg {
          font-size: 3rem;
          color: ${({ theme }) => theme.colors.primary};
        }

        h4 {
          margin: 14px 0px;
          font-weight: 600;
          font-size: 1.25rem;
          line-height: 115%;
          color: ${({ theme }) => theme.colors.white};
        }

        p {
          font-size: 0.875rem;
          line-height: 140%;
          letter-spacing: -0.005em;
          color: ${({ theme }) => theme.colors.white};
          opacity: 0.7;
          margin-bottom: 1.5rem;
        }

        label {
          font-size: 1rem;
          line-height: 140%;
          color: ${({ theme }) => theme.colors.white};
        }

        form {
          width: 100%;
          margin-top: 0.5625rem;
          padding: 20px 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background: ${({ theme }) => theme.colors.border};
          border-radius: 6px;
          padding-left: 16px;
          padding-right: 12px;

          textarea {
            border: 0;
            padding: 0;
            background: none;
            resize: none;
            flex: 1;
            padding-right: 20px;
            caret-color: ${({ theme }) => theme.colors.primary};
          }

          button {
            cursor: pointer;
            border: 0;
            background: none;
            transition: all 0.3s ease-in-out;
            font-size: 1.3rem;

            &:hover {
              color: ${({ theme }) => theme.colors.primary};
            }
          }
        }
      }
    }
  }
`;
