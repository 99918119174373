import { Link } from "react-router-dom";
import { Pagination, Autoplay, Parallax } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";

import { IoMdArrowDropright } from "react-icons/io";

import { Container } from "./styles";
import { HighLightsType } from "../../types/HighLights";

interface SliderHeroProps {
  data: HighLightsType[];
}

export const SliderHero = ({ data }: SliderHeroProps) => {
  return (
    <Container>
      <Swiper
        modules={[Parallax, Pagination, Autoplay]}
        slidesPerView={1}
        parallax={true}
        pagination={{ clickable: true }}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
      >
        {data.length > 0 ? (
          data.map((item, indexItem) => (
            <SwiperSlide key={indexItem}>
              <img
                src={`${process.env.REACT_APP_URL_SPACE}/${item.banner}`}
                alt={item.title}
                loading="lazy"
              />

              <div className="overlay">
                <div className="text">
                  <span>{item.header}</span>
                  <h1>{item.title}</h1>
                  <p>{item.description}</p>
                </div>

                <div className="buttons">
                  {item.button_label_1 && item.external ? (
                    <a
                      href={item?.button_link_1 || ""}
                      className="btn-white"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {item?.button_label_1}
                      <IoMdArrowDropright />
                    </a>
                  ) : (
                    <Link to={item?.button_link_1 || ""} className="btn-white">
                      {item?.button_label_1}
                      <IoMdArrowDropright />
                    </Link>
                  )}

                  {item.button_label_2 && item.external ? (
                    <a
                      href={item?.button_link_2 || ""}
                      className="btn-outline"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {item?.button_label_2}
                      <IoMdArrowDropright />
                    </a>
                  ) : (
                    <Link
                      to={item?.button_link_2 || ""}
                      className="btn-outline"
                    >
                      {item?.button_label_2}
                    </Link>
                  )}
                </div>
              </div>
            </SwiperSlide>
          ))
        ) : (
          <SwiperSlide>
            <img
              src={"/static/images/ttribo-banner.jpeg"}
              alt="TTribo.com"
              loading="lazy"
            />

            <div className="overlay">
              <div className="text">
                <span>TTribo</span>
                <h1>Seja bem-vindo(a)</h1>
                <p>Plataforma de apredizagem e mentoria</p>
              </div>
            </div>
          </SwiperSlide>
        )}
      </Swiper>
    </Container>
  );
};
